// useFormDataWatchers.js
import { watch } from 'vue'

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/** Validation */
const validators = {
  email: (v: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(v) || 'Please enter a valid email address'
  },
  required: (v: any) => !!v || 'This field is required',
  phoneFormat: (value: string) =>
    !!value || /^010-\d{4}-\d{4}$/.test(value) || 'Phone number must be in 010-XXXX-XXXX format.',
  comfirmationCodeFormat: (value: string) => /^\d{6}$/.test(value) || 'Confirmation code must be a 6-digit number.',
}

function useFormDataWatchers(formData: { phone: string; confirmationCode: any }) {
  watch(
    () => formData.phone,
    (newValue) => {
      if (!newValue.startsWith('010-')) {
        formData.phone = `010-${newValue.replace(/\D/g, '')}`
        return
      }
      let numericValue = newValue.slice(4).replace(/\D/g, '')
      numericValue = numericValue.length > 8 ? numericValue.substring(0, 8) : numericValue
      formData.phone = `010-${numericValue.substring(0, 4)}${
        numericValue.length > 4 ? '-' + numericValue.substring(4) : ''
      }`
    },
    { immediate: true },
  )

  watch(
    () => formData.confirmationCode,
    (newValue) => {
      const numericValue = newValue.replace(/\D/g, '')
      formData.confirmationCode = numericValue.substring(0, 6)
    },
    { immediate: true },
  )
}

function getFormattedPhoneNum(phone: string) {
  return '+82' + phone.replace(/-/g, '')
}

function getFormattedDate(date: string) {
  return new Date(date).toLocaleDateString()
}

export { sleep, validators, useFormDataWatchers, getFormattedPhoneNum, getFormattedDate }
