import { AxiosRequestConfig } from 'axios'
import Repository from './Repository'
// import { sign } from 'node:crypto'

type Data = {
  username: string
  confirmationCode?: string
}

type Headers = {
  'Content-Type': string
  'auth-session': string
}

export default {
  getUser(userId: string) {
    return Repository.get(`/User/${userId}`)
  },
  getAllUsers(pageNumber: number, pageSize: number) {
    return Repository.get(`/User/get-all-users?pageNumber=${pageNumber}&pageSize=${pageSize}`)
  },
  getUserByPhoneNum(phoneNum: string) {
    return Repository.get(`/User/phone/${phoneNum}`)
  },
  loginWithPhone(data: Data) {
    return Repository.post('auth/sign-in/', data)
  },
  confirmSignIn({ data, headers }: { data: Data; headers: Headers }) {
    const config: AxiosRequestConfig = {
      headers,
    }
    return Repository.post('auth/confirm-sign-in/', data, config)
  },
  signUp(data: Data) {
    return Repository.post('auth/sign-up/', data)
  },
  confirmSignUp({ data, headers }: { data: Data; headers: Headers }) {
    const config: AxiosRequestConfig = {
      headers,
    }
    return Repository.post('auth/confirm-sign-up/', data, config)
  },
  deleteUser(userId: string) {
    return Repository.delete(`/User?id=${userId}`)
  },
}
