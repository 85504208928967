<template>
  <VaForm ref="form" @submit.prevent="submit">
    <p class="text-base mb-3 leading-3" :class="currentPresetName === 'dark' ? 'text-dark' : 'text-light'">
      회원가입이 필요하신가요?
      <RouterLink :to="{ name: 'signup' }" class="font-semibold text-primary">가입하기</RouterLink>
    </p>
    <div class="flex items-center">
      <VaInput
        v-model="formData.phone"
        :rules="[validators.required, validators.phoneFormat]"
        class="mb-4 flex-grow"
        label="전화번호 인증"
        type="phone"
        :disabled="confirmationCodeInputEnabled"
        @keyup.enter="submit"
      />
      <span class="reset-icon cursor-pointer ml-2" @click="resetFormData">&#x274C;</span>
    </div>
    <div v-if="confirmationCodeInputEnabled" class="flex items-center">
      <VaInput
        id="confirmationCode"
        v-model="formData.confirmationCode"
        :rules="[validators.comfirmationCodeFormat]"
        class="mb-4"
        label="인증 코드 입력"
        type="text"
        placeholder="인증 코드를 입력하세요"
        @keyup.enter="submit"
      />
      <span class="reload-icon cursor-pointer ml-2" title="Resend confirmation code" @click="sendConfirmationCode"
        >&#x1F501;</span
      >
    </div>
    <div class="auth-layout__options flex flex-col sm:flex-row items-start sm:items-center justify-between">
      <VaCheckbox v-model="formData.keepLoggedIn" class="sm:mb-0" label="이 기기에서 로그인 상태 유지" />
    </div>

    <!-- <div class="flex justify-center mt-3">
      <VaButton class="w-full" @click="submit"> {{ loginButtonText }}</VaButton>
    </div> -->
  </VaForm>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useForm, useToast } from 'vuestic-ui'
import { useUserStore, User } from '../../../stores/user-store'
import { AxiosResponse } from 'axios'
import { validators, useFormDataWatchers, getFormattedDate } from '../../../services/utils'
import { confirmSignIn, getUserByPhoneNum, loginWithPhone } from '../../../services/UserService'
import { useColors } from 'vuestic-ui'

const { validate } = useForm('form')
const { push } = useRouter()
const { init } = useToast()
const { currentPresetName } = useColors()

const formData = reactive({
  phone: '',
  keepLoggedIn: true,
  confirmationCode: '',
})

useFormDataWatchers(formData)

onMounted(() => {
  localStorage.removeItem('user')
})

const confirmationCodeInputEnabled = ref(false)
const loginButtonText = ref('Login')
const authSession = ref('')

const resetFormData = () => {
  formData.phone = ''
  formData.confirmationCode = ''
  formData.keepLoggedIn = true
  confirmationCodeInputEnabled.value = false
  loginButtonText.value = 'Login'
}

const submit = async () => {
  if (!validate()) return

  if (!confirmationCodeInputEnabled.value) {
    sendConfirmationCode()
  } else {
    await confirmSignIn({
      phone: formData.phone,
      confirmationCode: formData.confirmationCode,
      authSession: authSession.value,
    })
      .then((response) => handleConfirmationResponse(response))
      .catch((error) => {
        console.error(error)
        init({ message: 'Confirmation failed.', color: 'danger' })
      })
  }
}

const sendConfirmationCode = async () => {
  await loginWithPhone(formData.phone)
    .then((response) => handleLoginResponse(response))
    .catch((error) => {
      console.error(error)
      init({ message: 'Login failed.', color: 'danger' })
    })
  formData.confirmationCode = ''
}

const handleLoginResponse = (response: AxiosResponse<any, any>) => {
  if (response.status !== 200) {
    init({ message: 'Login failed.', color: 'error' })
    return
  }

  if (response.data.resultType === 1) {
    init({ message: 'Confirmation message successfully sent.', color: 'success', duration: 1000 })
    confirmationCodeInputEnabled.value = true
    loginButtonText.value = 'Confirm'
    authSession.value = response.data.session
    nextTick(() => {
      document.getElementById('confirmationCode')?.focus()
    })
  } else {
    init({ message: 'The entered phone number is invalid.', color: 'danger', duration: 1000 })
  }
}

const handleConfirmationResponse = async (response: AxiosResponse<any, any>) => {
  console.log('handleConfirmationResponse', response)
  const resultType = response.data.resultType

  if (resultType !== 1) {
    if (resultType === 0) {
      init({ message: 'Code does not match the expected format.', color: 'danger', duration: 1000 })
    } else if (resultType === 2) {
      init({
        message: 'Please verify your information and try resending the confirmation code.',
        color: 'danger',
        duration: 1000,
      })
    }
    return
  }

  init({ message: 'Login successful.', color: 'success', duration: 1000 })

  let userResponse: AxiosResponse<any, any> | undefined

  await getUserByPhoneNum(formData.phone).then((response) => {
    userResponse = response
  })

  if (!userResponse || userResponse.status !== 200) {
    init({ message: 'Failed to get user data.', color: 'danger', duration: 1000 })
    return
  }
  console.log('userResponse', userResponse.data)

  const userData: User = {
    id: userResponse.data.userId,
    email: userResponse.data.pmail,
    phoneNumber: userResponse.data.phoneNumber,
    userName: userResponse.data.username,
    memberSince: getFormattedDate(userResponse.data.createdAt),
    accessToken: response.data.accessToken,
    refreshToken: response.data.refreshToken,
    expiresIn: Date.now() + 3600 * 1000 * 24 * 30,
    is2FAEnabled: false,
    keepLoggedIn: formData.keepLoggedIn,
  }
  //

  const userStore = useUserStore()
  userStore.setUser(userData)
  localStorage.setItem('user', JSON.stringify(userData))
  push({ name: 'dashboard' })
}
</script>
