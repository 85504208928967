import { defineStore } from 'pinia'

export interface User {
  id: string
  email: string
  userName: string
  phoneNumber: string
  is2FAEnabled: boolean
  keepLoggedIn: boolean
  memberSince?: string
  pfp?: string
  accessToken: string
  refreshToken: string
  expiresIn: number
}

export const useUserStore = defineStore('user', {
  state: (): User => {
    // Try to get user data from localStorage
    const cachedUserData = localStorage.getItem('user')
    const user = JSON.parse(cachedUserData || '{}')
    console.log('useUserLog', user)
    if (user.id != '' && user.keepLoggedIn) {
      // If user data is found in cache, parse it and return
      return user
    } else {
      // If no user data in cache, return default state
      return {
        id: '',
        email: '',
        phoneNumber: '',
        password: '',
        userName: '',
        keepLoggedIn: false,
        is2FAEnabled: false,
        memberSince: '',
        accessToken: '',
        refreshToken: '',
        expiresIn: 0,
      } as User
    }
  },

  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    changeUserName(userName: string) {
      this.userName = userName
    },

    // Set User
    setUser(user: User) {
      Object.assign(this, user)
    },

    // Reset User
    resetUser() {
      this.id = ''
      this.email = ''
      this.phoneNumber = ''
      this.userName = ''
      this.keepLoggedIn = false
      this.is2FAEnabled = false
      this.memberSince = ''
      this.accessToken = ''
      this.refreshToken = ''
      this.expiresIn = 0
    },

    getUserName() {
      return this.userName
    },
  },
})
