<template>
  <footer class="max-w-screen-xl mx-auto p-4 border-t bg-gray-50">
    <div class="mb-1">OverK</div>
    <div>서울특별시 강남구 봉은사로 418, 5층 560호(삼성동, HS빌딩)</div>
    <div class="mt-4 flex text-center">
      <RouterLink :to="{ name: 'terms' }" class="block me-12">이용약관</RouterLink>
      <RouterLink :to="{ name: 'privacy' }" class="block me-12">개인정보처리방침</RouterLink>
      <RouterLink :to="{ name: 'faq' }" class="block me-12">FAQ</RouterLink>
      <a class="block" style="color: green" href="https://blog.naver.com/oi" target="_blank">Naver Blog</a>
    </div>
  </footer>
</template>
