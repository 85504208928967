import UserRepository from '../repository/UserRepository'
import { getFormattedPhoneNum } from './utils'

const loginWithPhone = async (phone: string) => {
  try {
    const formattedPhone = '+82' + phone.replace(/-/g, '')
    return await UserRepository.loginWithPhone({ username: formattedPhone })
  } catch (error) {
    console.error('loginWithPhone error', error)
    throw error
  }
}

const confirmSignIn = async ({
  phone,
  confirmationCode,
  authSession,
}: {
  phone: string
  confirmationCode: string
  authSession: string
}) => {
  try {
    return await UserRepository.confirmSignIn({
      data: {
        username: getFormattedPhoneNum(phone),
        confirmationCode,
      },
      headers: {
        'Content-Type': 'application/json',
        'auth-session': authSession,
      },
    })
  } catch (error) {
    console.error('confirmSignIn error', error)
    throw error
  }
}

const getUser = async (userId: string) => {
  try {
    return await UserRepository.getUser(userId)
  } catch (error) {
    console.error('getUser error', error)
    throw error
  }
}

const getUserByPhoneNum = async (phoneNum: string) => {
  try {
    return await UserRepository.getUserByPhoneNum(getFormattedPhoneNum(phoneNum))
  } catch (error) {
    console.error('getUserByPhoneNum error', error)
    throw error
  }
}

const getAllUsers = async (pageNumber: number, pageSize: number) => {
  try {
    return await UserRepository.getAllUsers(pageNumber, pageSize)
  } catch (error) {
    console.error('getAllUsers error', error)
    throw error
  }
}

export { loginWithPhone, confirmSignIn, getUser, getUserByPhoneNum, getAllUsers }
